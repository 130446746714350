import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { Footer } from '../../Components';
import { sendMail } from '../../Services/MailService';
import { CAPTCHA_KEY } from '../../Config';

export default function AboutView() {
  const [state, setState] = useState({
    email: null,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const onSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await sendMail(state);

      if (res?.data?.status) {
        alert('We have received your message. We will get back to you soon');
        setIsLoading(false);
        setState({ ...state, email: '', message: '' });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      alert('Something went wrong!');
    }
  };

  const onVerifyCaptcha = () => {
    setDisabledSubmit(false);
  };

  return (
    <div className="min-h-screen flex flex-col h-screen">
      <div className="flex-grow">
        <div className="max-w-screen-xl mx-auto px-5 bg-white">
          <div className="flex flex-col items-center lg:mt-10 sm:mt-6 md:mt-6">
            <div>
              <Link to={'/'}>
                <h1 className="lg:text-3xl sm:text-xl text-center font-bold underline text-blue-600">
                  AUTOMATIC DOMAIN SEARCH
                </h1>
              </Link>
            </div>
            <p className="text-blue-600 text-xl mt-3">
              Brainstorm your next domain
            </p>
          </div>
          <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
            <div className="py-2">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-indigo-900">
                  <span> How often are the domains updated</span>
                  <span className="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                  Domains are updated every day at around 1.00 AM UTC at night.
                  There is a one day delay from the time a domain is registered
                  and it is reflected in our database.
                </p>
              </details>
            </div>
            <div className="py-2">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-indigo-900">
                  <span> How do you make money?</span>
                  <span className="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                  We receive a small commssion from the affliate links when
                  someone purchases a domain through our links. There is no
                  additional extra cost to the customer.
                </p>
              </details>
            </div>
            <div className="py-2">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-indigo-900">
                  <span> Who created this website?</span>
                  <span className="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                  This website is created by Charles. I am a data engineer
                  working in Germany. If you have interesting work or want me to
                  do consulting work, you can reach out to me using the below
                  contact form.
                </p>
              </details>

              <div className="mt-8"></div>
              <form onSubmit={onSubmit}>
                <p className="text-3xl font-bold leading-7 text-center">
                  Contact me
                </p>

                <label for="email" className="leading-7 text-sm text-gray-900">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={state.email}
                  onChange={e => setState({ ...state, email: e.target.value })}
                  className="w-full bg-white rounded-md border border-gray-900 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 text-sm outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"></input>
                <div className="relative mb-4">
                  <label
                    for="message"
                    className="leading-7 text-sm text-gray-900">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    required
                    value={state.message}
                    onChange={e =>
                      setState({ ...state, message: e.target.value })
                    }
                    rows="4"
                    className="w-full bg-white rounded-md border border-gray-900 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 text-sm outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"></textarea>
                </div>
                <div className="mb-4">
                  <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={onVerifyCaptcha} />
                </div>
                <button
                  type="submit"
                  disabled={disabledSubmit}
                  className="w-full text-white bg-indigo-500 rounded-md border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 text-lg">
                  {isLoading ? 'Sending...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
