import React from 'react';
import { IconButton } from '@material-tailwind/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';

export const BackToTopButton = () => {
  return (
    <div
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}
      style={{
        position: 'fixed',
        fontSize: '20px',
        bottom: '40px',
        right: '20px',
        textAlign: 'center',
      }}>
      <IconButton className="bg-blue-600">
        <ChevronUpIcon className="h-6 w-6 text-white" />
      </IconButton>
    </div>
  );
};
