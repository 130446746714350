import React from 'react';
import { Footer } from '../../Components';
import { Link } from 'react-router-dom';
import { Menu } from '../../Menu';

export default function How_to_find_amazing_domains() {
  return (
<div class="flex flex-col h-screen justify-between"> 
<Menu /> 
{/* <div class="py8"> */}
<div><Link  to={'/'}>  
      <h1 class="text-3xl text-center font-bold underline text-blue-600">AUTOMATIC DOMAIN SEARCH</h1>
      </Link></div>
<div class="flex flex-col items-center"> 
<div class="container my-2.5 mx-auto md:px-6">
  {/* <!-- Section: Design Block --> */}
  <section class="mb-3 mt-8">
    <h1 class="mb-4 text-3xl font-bold">
      How to find amazing domain names
    </h1>
    <p class="mb-6 flex items-center font-bold uppercase text-danger dark:text-danger-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
        class="mr-2 h-5 w-5">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
        </svg>Blog
    </p>

    <p class="mb-6">
    It can be hard to find .com domains. Mainly because there are currently 159 million .com domains registered as of 2023. After all there are only 26 letters in the alphabet. There are literally no 4 letter domains available in any combination. If you tell a person on the road your domain name, he/she should be able to remember it when he/she reaches home. That essentially is the hallmark of a good domain name.
    </p>

    <p class="mb-6">
    It is always preferable to have a .com domain as it is the most popular and widely used domain. Here are a few tips and pointers that I use to grab some good domain names. The first step to discovering a remarkable .com domain name is brainstorming. Try different word play with the brand that you have in mind. Single-Word Domains: While single-word .com domains are highly sought after, they can be challenging to find. But if you stumble upon one that fits your brand, it's a golden opportunity.
    </p>
    <p class="mb-6">
      <strong>Synonyms and Thesaurus</strong>
    </p>

    <p class="mb-6">
    Expand your list by exploring synonyms and consulting a thesaurus. This can lead to unexpected, unique combinations. In the age of rapid information consumption, shorter domain names are often more memorable and easier to share. Aim for a name that is concise and easy to type.
    </p>

    {/* <p
      class="mb-6 rounded border-l-4 border-neutral-800 bg-neutral-100 p-2 dark:border-neutral-200 dark:bg-neutral-700">
      <strong>Note:</strong> Lorem ipsum dolor sit amet, consectetur
      adipisicing elit. Optio odit consequatur porro sequi ab distinctio
      modi. Rerum cum dolores sint, adipisci ad veritatis laborum eaque
      illum saepe mollitia ut voluptatum.
    </p> */}

    <p class="mb-6">
      <strong>Combine two words</strong>
    </p>

    <p class="mb-6">
    Compound Words and Blends: Combine two relevant words to form a new, catchy term. Blend words together if it makes sense for your brand.Some of the sample two word domains still available as of 24 Aug 2023 are BlackboardData.com. dirtymashup.com. Try to combine two words that makes sense for your brand.
    </p>

    {/* <img src="https://mdbcdn.b-cdn.net/img/new/slides/194.jpg"
        class="mb-6 w-full rounded-lg shadow-lg dark:shadow-black/20" alt="" /> */}

    {/* <ul class="mb-6 list-inside list-disc">
      <li>Lorem</li>
      <li>Ipsum</li>
      <li>Dolor</li>
      <li>Sit</li>
      <li>Amet</li>
    </ul> */}

    <p class="mb-4">
      <strong>Things to avoid</strong>
    </p>
    <p>
    Avoid Hyphens and Numbers: Hyphens and numbers can confuse potential visitors and make your domain name harder to remember.    
    </p>
    <p class="mb-4">
        Avoid Trends: While incorporating current trends can be appealing, they can also quickly become outdated.
    </p>
    <p class="mb-6">
      <strong>Happy domaining!!</strong>
    </p>    
<div>
</div> 
   
</section> 
</div>
</div>
<Menu />

  <Footer /> 
  </div> 
  );
}




