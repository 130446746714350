export const Constant = {
  TOKEN: 'TOKEN',
  AUTHENTICATED: 'AUTHENTICATED',
};

export const DOMAINS_SERVERS = [
  { label: 'NAMECHEAP.COM', value: 'namecheap' },
  { label: 'GODADDY.COM', value: 'godaddy' },
  { label: 'NAMESILO.COM', value: 'namesilo' },
];

export const SortBy = [
  { label: 'Rank', value: 'Rank' },
  { label: 'Length', value: 'Length' },
  { label: 'Alphabetical', value: 'Alphabetical' },
];

export const FilterBy = [
  { label: 'All', value: 'All' },
  { label: 'Start with Keyword', value: 'Begin' },
  { label: 'End with Keyword', value: 'End' },
];

export const DOMAINS = ['Datentify.com', 'Datentify.net', 'Datentify.org'];
