import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import {
  fetchNames,
  saveNamePageData,
  saveSearchTerm,
  fetchDomainAvailable,
  getCount,
} from '../../Services/SearchService';

import { DOMAINS_SERVERS, SortBy, FilterBy } from '../../Constant';
import { getFormattedWord, getLink } from '../../Util/Common';

import { Footer, Select, BackToTopButton } from '../../Components';

export default function NameGeneratorView() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [indexText, setIndexText] = useState('');
  const [domainServer, setDomainServer] = useState(DOMAINS_SERVERS[2].value);
  const [responseTime, setResponseTime] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [count, setCount] = useState('');

  const handleSearch = async () => {
    if (searchText && searchText.length > 1) {
      try {
        setIsLoading(true);
        setIndexText(searchText);
        const startTime = performance.now();

        const res = await fetchNames(searchText);
        const res2 = await fetchDomainAvailable(searchText);
        const res3 = await getCount();
        let data = {
          searchTerm: searchText,
        };
        await saveSearchTerm(data);

        const endTime = performance.now();
        const elapsedTimeInSeconds = ((endTime - startTime) / 1000).toFixed(3);

        if (res.success && res2.success && res3.success) {
          setFilterData(res.data);
          setMasterData(res.data);
          setResponseTime(elapsedTimeInSeconds);
          setIsAvailable(res2.data.isAvailable);
          setCount(res3.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert('Something went wrong!');
        }
      } catch (err) {
        alert('Something went wrong!');
      }
    } else {
      setErrorMessage('Minimum of 2 characters');
      setShowError(true);
    }
  };

  const handleInput = e => {
    const inputText = e.target.value;
    const lettersOnly = /^[A-Za-z]+$/;

    if (lettersOnly.test(inputText) || inputText === '') {
      if (inputText.length >= 15) {
        setSearchText(inputText);
        setErrorMessage('Max 15 letters');
        setShowError(true);
      } else {
        setSearchText(inputText);
        setShowError(false);
        setErrorMessage('');
      }
    } else {
      setErrorMessage('Letters only');
      setShowError(true);
    }
  };

  const handleKeyEvent = async e => {
    if (e.key === 'Enter') {
      await handleSearch();
    }
  };

  const handleFilter = e => {
    const value = e.target.value;

    let data = [...masterData];

    switch (value) {
      case 'Rank':
        data.sort((a, b) => a.Rank - b.Rank);
        break;
      case 'Alphabetical':
        data.sort((a, b) => a.Word.localeCompare(b.Word));
        break;
      case 'Length':
        data.sort((a, b) => a.Word.length - b.Word.length);
        break;
      case 'Begin':
        data = data.filter(item => item.Append === 'End');
        break;
      case 'End':
        data = data.filter(item => item.Append === 'Begin');
        break;
      case 'All':
        data = masterData;
      default:
        setFilterData(masterData);
        break;
    }

    setFilterData(data);
  };

  let rows = [];

  for (let i = 0; i < filterData.length; i += 2) {
    rows.push(filterData.slice(i, i + 2));
  }

  const selectHandler = e => {
    const value = e.target.value;
    setDomainServer(value);
  };

  const onClickDomain = async domain => {
    await saveData(domain);
    window.open(getLink(domain, domainServer, false));
  };

  const saveData = async domain => {
    try {
      let data = {
        searchTerm: searchText,
        clickedValue: domain,
        valueOfDropdown: domainServer,
        link: getLink(domain, domainServer, false),
      };
      await saveNamePageData(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="lg:flex flex-col items-center justify-center flex-1 sm: mt-10 mx-4">
        <div className="">
          <div>
            <Link to={'/'}>
              <h1 className="lg:text-3xl text-center font-bold underline text-blue-600 sm:text-xl">
                AUTOMATIC DOMAIN SEARCH
              </h1>
            </Link>
          </div>
          <p className="text-center text-blue-600 text-xl">
            Brainstorm your next domain
          </p>

          {/* search bar */}
          <div className="lg:flex lg:justify-center sm:grid sm:grid-rows-1 gap-4 mt-4">
            <div className="flex relative items-center px-1 py-1 border border-gray-900 rounded lg:w-[350px]">
              <input
                className="ml-2 text-xl font-normal w-full focus:outline-none"
                placeholder="Type keyword.."
                maxLength={15}
                onChange={handleInput}
                onKeyDown={handleKeyEvent}
                value={searchText}
              />
              <div onClick={handleSearch}>
                <MagnifyingGlassIcon className="h-6 w-6 text-gray-600" />
              </div>
              {showError && (
                <p className="absolute text-sm text-red-500 right-10">
                  {errorMessage}
                </p>
              )}
            </div>
            <Select
              data={DOMAINS_SERVERS}
              defaultValue={DOMAINS_SERVERS[2].value}
              selectHandler={selectHandler}
              size="large"
            />
          </div>

          {/* result */}
        </div>
      </div>
      <div className="">
        {isLoading ? (
          <div className="lg:grid lg:grid-cols-2 sm:grid-rows-1 lg:gap-4 animate-pulse pb-52 px-5">
            {Array(10)
              .fill()
              .map((_, idx) => (
                <div
                  key={idx}
                  className="lg:p-2 sm:pt-1 h-2 bg-gray-400 mt-1"></div>
              ))}
          </div>
        ) : (
          rows.length !== 0 && (
            <>
              {isAvailable && (
                <div className="flex justify-center transition-opacity duration-500 mt-5">
                  <div className="flex justify-between lg:space-x-36 sm:space-x-10 border px-10 py-5">
                    {' '}
                    <p className="font-bold lg:text-lg">
                      <span className="capitalize">{indexText}</span>.com
                    </p>
                    <a
                      className="font-bold text-blue-700 cursor-pointer"
                      onClick={() => onClickDomain(`${indexText}.com`)}>
                      Buy Domain
                    </a>
                  </div>
                </div>
              )}
              <p className="text-center lg:text-base sm:text-sm font-medium my-10">
                Searched database of <span className="font-bold">{count}</span>{' '}
                rows and found{' '}
                <span className="font-bold">
                  {filterData.length.toLocaleString()} available domains
                </span>{' '}
                containing{' '}
                <span className="font-bold capitalize">"{indexText}"</span> in{' '}
                {responseTime} seconds
              </p>

              <div className="flex justify-between mt-5 px-5">
                <div className="flex items-center space-x-2">
                  <p className="text-base font-medium text-blue-500">Sort By</p>
                  <Select
                    data={SortBy}
                    defaultValue={SortBy[0].value}
                    selectHandler={handleFilter}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <p className="text-base font-medium text-blue-500">
                    Filter By
                  </p>
                  <Select
                    data={FilterBy}
                    defaultValue={FilterBy[0].value}
                    selectHandler={handleFilter}
                  />
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-2 sm:grid-rows-1 lg:gap-4 my-5 px-5">
                {rows.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="lg:border lg:border-gray-300 lg:p-2 sm:pt-1 lg:divide-y lg:divide-solid">
                    {row.map((result, index) => (
                      <div key={index} className="p-2 flex justify-between ">
                        {getFormattedWord(
                          result.Word.replace('.com', ''),
                          indexText.toLocaleUpperCase(),
                        )}
                        <a
                          className="text-sm text-blue-700 cursor-pointer"
                          onClick={() => onClickDomain(result.Word)}>
                          .com
                        </a>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </div>
      {rows.length !== 0 && <BackToTopButton />}
      <Footer />
    </div>
  );
}
