import React from 'react';

export const Skelton = () => {
  return (
    <div className="float-right lg:w-[245px] sm:w-[110px] text-center py-1 px-2 border-black rounded-lg border-2">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6">
          <div className="space-y-3">
            <div className="grid grid-cols-4 gap-4">
              <div className="h-2 bg-red-400 col-span-1"></div>
              <div className="h-2 bg-green-400 col-span-2"></div>
              <div className="h-2 bg-red-400 col-span-1"></div>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <div className="h-2 bg-green-400 col-span-2"></div>
              <div className="h-2 bg-red-400 col-span-1"></div>
              <div className="h-2 bg-green-400 col-span-1"></div>
              <div className="h-2 bg-red-400 col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
