import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import SearchView from './Views';
import AboutView from './Views/About';
import GuideView from './Views/Guides';
import How_to_find_amazing_domains from './Views/Articles/1How_to_find_amazing_domains';
import Evolution_of_domain_names from './Views/Articles/2Evolutionofdomains';
import Branding from './Views/Articles/3Branding';
import Voicesearch from './Views/Articles/4voicesearch';
import NameGeneratorView from './Views/NameGenerator';

const TRACKING_ID = 'G-6C22HEBWWG';
ReactGA.initialize(TRACKING_ID);

ReactGA.send({
  hitType: 'pageview',
  page: '/',
  title: 'Automatic Domain Search',
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <SearchView />,
  },
  {
    path: '/name-generator',
    element: <NameGeneratorView />,
  },
  {
    path: '/about',
    element: <AboutView />,
  },
  {
    path: '/guide',
    element: <GuideView />,
  },
  {
    path: '/guide/How_to_find_amazing_domains',
    element: <How_to_find_amazing_domains />,
  },
  {
    path: '/guide/Evolution_of_domain_names',
    element: <Evolution_of_domain_names />,
  },
  {
    path: '/guide/Branding',
    element: <Branding />,
  },
  {
    path: '/guide/Voicesearch',
    element: <Voicesearch />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
