import React from 'react';
import { Footer } from '../../Components';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Menu } from '../../Menu';

export default function GuideView() {
  return (
    <div className="min-h-screen flex flex-col h-screen">
      <div className="flex-grow">
        <div className="flex flex-col items-center lg:mt-10 sm:mt-6 md:mt-6">
          <div className="w-10/12 md:w-7/12 lg:6/12 mx-auto relative ">
            <div>
              <Link to={'/'}>
                <h1 className="lg:text-3xl sm:text-xl text-center font-bold underline text-blue-600">
                  AUTOMATIC DOMAIN SEARCH
                </h1>
              </Link>
            </div>
            <div className="border-l-2 mt-10">
              {/* <!-- Card 1 --> */}
              <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-blue-600 text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                {/* <!-- Dot Follwing the Left Vertical Line --> */}
                <div className="w-5 h-5 bg-blue-600 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>
                {/* <!-- Line that connecting the box with the vertical line --> */}
                <div className="w-10 h-1 bg-blue-300 absolute -left-10 z-0"></div>
                {/* <!-- Content that showing in the box --> */}
                <div className="flex-auto">
                  {/* <h1 className="text-lg">Day 1</h1> */}
                  {/* <a href="/blog1"> */}
                  <Link to={'/guide/How_to_find_amazing_domains'}>
                    <h1 className="text-xl font-bold">
                      How to find amazing domain names
                    </h1>
                  </Link>
                  {/* </a> */}
                  {/* <h3>classNameroom</h3>             */}
                </div>
              </div>

              {/* <!-- Card 2 --> */}
              <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-pink-600 text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                {/* <!-- Dot Follwing the Left Vertical Line --> */}
                <div className="w-5 h-5 bg-pink-600 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>
                {/* <!-- Line that connecting the box with the vertical line --> */}
                <div className="w-10 h-1 bg-pink-300 absolute -left-10 z-0"></div>
                {/* <!-- Content that showing in the box --> */}
                <div className="flex-auto">
                  <Link to={'/guide/Evolution_of_domain_names'}>
                    <h1 className="text-xl font-bold">
                      Evolution of Domain Names
                    </h1>
                  </Link>
                  {/* <h3>classNameroom</h3> */}
                </div>
              </div>

              {/* <!-- Card 3 --> */}
              <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-green-600 text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                {/* <!-- Dot Follwing the Left Vertical Line --> */}
                <div className="w-5 h-5 bg-green-600 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>
                {/* <!-- Line that connecting the box with the vertical line --> */}
                <div className="w-10 h-1 bg-green-300 absolute -left-10 z-0"></div>
                {/* <!-- Content that showing in the box --> */}
                <div className="flex-auto">
                  <Link to={'/guide/Branding'}>
                    <h1 className="text-xl font-bold">
                      Domain names and Branding
                    </h1>
                  </Link>
                </div>
              </div>

              {/* <!-- Card 4 --> */}
              <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-purple-600 text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                {/* <!-- Dot Follwing the Left Vertical Line --> */}
                <div className="w-5 h-5 bg-purple-600 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                {/* <!-- Line that connecting the box with the vertical line --> */}
                <div className="w-10 h-1 bg-purple-300 absolute -left-10 z-0"></div>

                {/* <!-- Content that showing in the box --> */}
                <div className="flex-auto">
                  <Link to={'/guide/Voicesearch'}>
                    <h1 className="text-xl font-bold">
                      The Rise of Voice Search and Its Influence on Domain Names
                    </h1>
                  </Link>
                </div>
                {/* <a href="#" className="text-center text-white hover:text-gray-300">Download materials</a> */}
              </div>              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
