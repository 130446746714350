import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export const Select = ({ data, defaultValue, selectHandler, size = '' }) => {
  return (
    <div
      className={`flex px-2 border-gray-900 items-center rounded border ${
        size === 'large' && 'py-1'
      }`}>
      <select
        style={{
          WebkitAppearance: 'none',
          appearance: 'none',
          MozAppearance: 'none',
          backgroundColor: 'white',
        }}
        className="lg:text-base text-xl  focus:outline-none h-[30px] w-full"
        onChange={selectHandler}
        defaultValue={defaultValue}>
        {data.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {size === 'large' && (
        <ChevronDownIcon className="h-4 w-4 text-gray-900" />
      )}
    </div>
  );
};
