import { Config } from '../Config';
import * as httpClient from './HttpClient';

export const fetchDomains = async keyword => {
  const url = `${Config.API_URL}domain/${keyword}`;
  const response = await httpClient.get(url);
  return response;
};

export const fetchNames = async keyword => {
  const url = `${Config.API_URL}name/${keyword}`;
  const response = await httpClient.get(url);
  return response;
};

export const saveHomePageData = async data => {
  const url = `${Config.API_URL}domain/save`;
  return await httpClient.post(url, data);
};

export const saveNamePageData = async data => {
  const url = `${Config.API_URL}name/save`;
  return await httpClient.post(url, data);
};

export const saveAll = async data => {
  const url = `${Config.API_URL}name/saveAll`;
  return await httpClient.post(url, data);
};

export const saveSearchTerm = async data => {
  const url = `${Config.API_URL}name/saveSearchTerm`;
  return await httpClient.post(url, data);
};

export const fetchDomainAvailable = async keyword => {
  const url = `${Config.API_URL}name/mainDomain/${keyword}`;
  const response = await httpClient.get(url);
  return response;
};

export const getCount = async () => {
  const url = `${Config.API_URL}name/get/count`;
  const response = await httpClient.get(url);
  return response;
};
