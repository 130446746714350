import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { DOMAINS_SERVERS, DOMAINS } from '../Constant';

import { fetchDomains, saveHomePageData } from '../Services/SearchService';
import { Footer, Select, Skelton } from '../Components';
import { getLink } from '../Util/Common';

export default function SearchView() {
  const [searchText, setSearchText] = useState('');
  const [domainServer, setDomainServer] = useState(DOMAINS_SERVERS[2].value);
  const [domains, setDomains] = useState({
    com: null,
    net: null,
    org: null,
  });
  const [available, setAvailable] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchHandler = async e => {
    const keyword = e.target.value;
    setIsLoading(true);
    const absString = validateString(keyword);

    setSearchText(absString);

    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    if (absString && absString.length > 1) {
      const timer = setTimeout(async () => {
        const response = await fetchDomains(absString);
        if (response.success) {
          setDomains({
            ...domains,
            com: response.data.com_result,
            net: response.data.net_result,
            org: response.data.org_result,
          });
          setAvailable(true);
        } else {
          setAvailable(false);
          // setSearchText('');
        }
        setIsLoading(false);
      }, 500);

      setSearchTimer(timer);
    } else {
      setIsLoading(false);
      setAvailable(false);
      setDomains({ ...DOMAINS, com: null, net: null, org: null });
    }
  };

  const selectHandler = e => {
    const value = e.target.value;
    ReactGA.event({
      category: 'Dropdown',
      action: 'Select',
      label: value,
    });
    setDomainServer(value);
  };

  const onClickDomain = async (domain, domainStatus) => {
    ReactGA.event({
      category: domain ? 'whois' : 'buynow',
      action: 'Search',
      label: domainServer,
    });

    await saveData(domain, domainServer, domainStatus);
    window.open(getLink(domain, domainServer, domainStatus));
  };

  const saveData = async (domain, domainStatus) => {
    try {
      let data = {
        searchTerm: domain,
        valueOfButton: domain ? 'whois' : 'buynow',
        valueOfDropdown: domainServer,
        link: getLink(domain, domainServer, domainStatus),
      };
      await saveHomePageData(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  function validateString(input) {
    const cleanText = input.replace(/[^a-zA-Z0-9-]/g, '');
    const result = cleanText.replace(/^-+|-+$/g, '');
    return result;
  }

  return (
    <div className="min-h-screen flex flex-col h-screen">
      <div className="flex-grow">
        <div className="flex justify-center items-center lg:mt-10 sm:mt-6 md:mt-6">
          <div className="lg:w-9/12 sm:w-11/12 md:w-11/12">
            {/* <h2 className="text-center lg:text-4xl text-3xl uppercase text-blue-600"> */}
            <h2 className="lg:text-3xl sm:text-xl text-center font-bold text-blue-600">
              AUTOMATIC DOMAIN SEARCH
            </h2>
            {/* <p className="text-center font-thin text-sm text-blue-600"> */}
            <p className="text-center text-blue-600 text-xl">
              Brainstorm your next domain
            </p>
            <p className="text-center mt-4">
              <Link
                className="text-blue-600 text-xl border-2 border-blue-600 py-1 px-2 rounded"
                to={'/name-generator'}>
                Visit our domain name generator
              </Link>
            </p>
            <div className="grid lg:grid-flow-col sm:grid-rows-1 gap-4 mt-8">
              <div className="lg:col-span-12 flex items-center px-4 py-2 border border-gray-900 w-full rounded">
                <MagnifyingGlassIcon className="h-6 w-6 text-gray-600" />
                <input
                  className="ml-2 text-xl font-normal w-full focus:outline-none"
                  placeholder="Search..."
                  onChange={searchHandler}
                  maxLength={63}
                />
              </div>
              <Select
                data={DOMAINS_SERVERS}
                defaultValue={DOMAINS_SERVERS[2].value}
                selectHandler={selectHandler}
                size="large"
              />
            </div>
            <div className="h-72 pt-10">
              {searchText.length === 1 ? (
                <div className=" bg-gray-200 py-5 rounded-xl ">
                  <p className="text-center text-2xl font-bold">Searching...</p>
                  <p className="text-center text-base mt-2 font-normal text-gray-600">
                    Domains should have a minimum of 2 characters!
                  </p>
                </div>
              ) : (
                searchText.length >= 2 && (
                  <div className="grid grid-cols-2">
                    <>
                      <div className="mb-8">
                        <p className="lg:text-2xl sm:text-lg">
                          {/* {domains.net?.result}                        */}
                          {searchText.toLowerCase()}.com
                        </p>
                      </div>
                      <div className="mb-8">
                        {isLoading ? (
                          <Skelton />
                        ) : (
                          <a
                            className={`float-right uppercase lg:text-xl sm:text-sm  font-thin lg:w-[245px] sm:w-[110px] text-center py-1 border-black rounded-lg border-2 cursor-pointer ${
                              domains.com?.isAvailable
                                ? 'bg-red-400'
                                : 'bg-green-400'
                            }`}
                            onClick={() =>
                              onClickDomain(
                                domains.com?.result,
                                domains.com.isAvailable,
                              )
                            }
                            target="_blank">
                            {domains.com?.isAvailable ? 'Whois' : 'Buy Domain'}
                          </a>
                        )}
                      </div>
                    </>
                    <>
                      <div className="mb-8">
                        <p className="lg:text-2xl sm:text-lg">
                          {/* {domains.net?.result} */}
                          {searchText.toLowerCase()}.net
                        </p>
                      </div>
                      <div className="mb-8">
                        {isLoading ? (
                          <Skelton />
                        ) : (
                          <a
                            className={`float-right uppercase lg:text-xl sm:text-sm  font-thin lg:w-[245px] sm:w-[110px] text-center py-1 border-black rounded-lg border-2 cursor-pointer ${
                              domains.net?.isAvailable
                                ? 'bg-red-400'
                                : 'bg-green-400'
                            }`}
                            onClick={() =>
                              onClickDomain(
                                domains.net?.result,
                                domains.net.isAvailable,
                              )
                            }
                            target="_blank">
                            {domains.net?.isAvailable ? 'Whois' : 'Buy Domain'}
                          </a>
                        )}
                      </div>
                    </>
                    <>
                      <div className="mb-8">
                        <p className="lg:text-2xl sm:text-lg">
                          {/* {domains.org?.result} */}
                          {searchText.toLowerCase()}.org
                        </p>
                      </div>
                      <div className="mb-8">
                        {isLoading ? (
                          <Skelton />
                        ) : (
                          <a
                            className={`float-right uppercase lg:text-xl sm:text-sm  font-thin lg:w-[245px] sm:w-[110px] text-center py-1 border-black rounded-lg border-2 cursor-pointer ${
                              domains.org?.isAvailable
                                ? 'bg-red-400'
                                : 'bg-green-400'
                            }`}
                            onClick={() =>
                              onClickDomain(
                                domains.org?.result,
                                domains.org.isAvailable,
                              )
                            }
                            target="_blank">
                            {domains.org?.isAvailable ? 'Whois' : 'Buy Domain'}
                          </a>
                        )}
                      </div>
                    </>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
