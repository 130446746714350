import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 lg:px-16 gap-x-12 border-t border-blue-gray-50 py-3 text-center md:justify-between">
      {/* <a>&copy; 2023 AutomaticDomainSearch</a> */}
      <ul className="flex flex-wrap items-center gap-y-2 lg:gap-x-8 sm:gap-x-4">
        <li>
          <Link className="font-medium text-base" to={'/'}>
            Home
          </Link>
        </li>
        <li>
          <Link className="font-medium text-base" to={'/name-generator'}>
            Name Generator
          </Link>
        </li>
        <li>
          <Link className="font-medium text-base" to={'/about'}>
            About Us
          </Link>
        </li>
        <li>
          <Link className="font-medium text-base" to={'/guide'}>
            Guides
          </Link>
        </li>
      </ul>
      <a>&copy; 2023 AutomaticDomainSearch</a>
    </footer>
  );
};
