import { DOMAINS_SERVERS } from '../Constant';

// Define a function to split the result.Word and apply bold styling
export const getFormattedWord = (word, searchText) => {
  const index = word.toLowerCase().indexOf(searchText.toLowerCase());

  const beforeText = word.substring(0, index);
  const searchTextPart = word.substring(index, index + searchText.length);
  const afterText = word.substring(index + searchText.length);
  return (
    <p className="text-base font-light">
      <span className="font-bold">{beforeText}</span>
      {searchTextPart.charAt(0).toUpperCase() + searchTextPart.slice(1)}
      <span className="font-bold">{afterText}</span>
    </p>
  );
};

export const getLink = (domain, domainServer, domainStatus) => {
  if (domainServer == DOMAINS_SERVERS[0].value) {
    //namecheap
    if (!domainStatus) {
      return `https://namecheap.pxf.io/c/4932848/386170/5618?u=https%3A%2F%2Fwww.namecheap.com%2Fdomains%2Fregistration%2Fresults%2F%3Fdomain%3D${domain}`;
    } else {
      return `https://namecheap.pxf.io/c/4932848/386170/5618?u=https%3A%2F%2Fwww.namecheap.com%2Fdomains%2Fwhois%2Fresult%3Fdomain%3D${domain}`;
    }
  } else if (domainServer == DOMAINS_SERVERS[1].value) {
    //godaddy
    if (!domainStatus) {
      return `https://www.dpbolvw.net/click-101020816-10497118?url=https%3A%2F%2Fclick.godaddy.com%2Faffiliate%3Fisc%3Dcjcfos1%26url%3Dhttps%3A%2F%2Fwww.godaddy.com%2Fdomainsearch%2Ffind%3FdomainToCheck%3D${domain}%26cjelbDays%3D45`;
    } else {
        return `https://www.kqzyfj.com/click-101020816-11774111?url=https%3A%2F%2Fclick.godaddy.com%2Faffiliate%3Fisc%3Dcjcfos3%26url%3Dhttps%3A%2F%2Fwww.godaddy.com%2Fwhois%2Fresults.aspx%3Fdomain%3D${domain}%26cjelbDays%3D45`;
    }
  } else {
    //namesilo
    if (!domainStatus) {
      return `https://www.namesilo.com/domain/search-domains?rid=6f6c770we`;
    } else {
      return `https://www.namesilo.com/whois?rid=6f6c770we`;
    }
  }
};
